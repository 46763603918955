import { makeUuid } from 'usage-tracker-core/common/helpers';
export const getDeviceId = () => {
  if (!window.hubspot) {
    window.hubspot = {};
  }

  const hubspot = window.hubspot;

  if (!hubspot.deviceId) {
    hubspot.deviceId = makeUuid();
  }

  return hubspot.deviceId;
};