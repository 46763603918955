import { Flow } from 'signup-constants/Flow';
export class SignupAnchor {
  /**
   * Creates an instance of SignupAnchor
   *
   * @static
   * @param {HTMLAnchorElement} anchor
   * @param {SignupUrlGenerator} generator
   * @returns {SignupAnchor}
   * @memberof SignupAnchor
   */
  static create(anchor, generator) {
    return new SignupAnchor(anchor, generator);
  }
  /**
   * Creates an instance of SignupAnchor.
   *
   * @param {HTMLAnchorElement} anchor
   * @param {SignupUrlGenerator} generator
   * @memberof SignupAnchor
   */


  constructor(anchor, generator) {
    this.anchor = anchor;
    this.generator = generator;
    this.process();
  }
  /**
   * Gets the signup type from the registered anchor.
   * Defaults to a something if the type is invalid.
   *
   * @returns {String}
   * @memberof SignupAnchor
   */


  getSignupType() {
    const anchorSignupType = this.anchor.dataset.signupType;

    if (!anchorSignupType || !this.generator.isValidSignupType(anchorSignupType)) {
      this.generator.log(`Anchor %o contains an invalid signup type %s. Default to ${Flow.Crm}`, this.anchor, anchorSignupType);
      return Flow.Crm;
    }

    return anchorSignupType;
  }
  /**
   * Gets the query from the link.
   *
   * @returns {String} The formatted query string.
   * @memberof SignupAnchor
   */


  getSignupQuery() {
    const anchorSignupQuery = this.anchor.dataset.signupQuery;

    if (!anchorSignupQuery) {
      return {};
    }

    return anchorSignupQuery.split('&').reduce((prev, curr) => {
      const p = curr.split('=');
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
  }
  /**
   * Processes the link and mark as processed.
   *
   * @memberof SignupAnchor
   */


  process() {
    if (!this.anchor.dataset.processed) {
      this.anchor.setAttribute('href', this.generator.getSignupUrl(this.getSignupType(), this.getSignupQuery()));
      this.anchor.dataset.processed = '';
    }
  }

}