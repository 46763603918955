export const DesignKitGeneratorTypeDecorator = {
  url() {
    return `signup-hubspot/integrations`;
  },

  query() {
    return {
      integration: 'design-kit-generator'
    };
  }

};