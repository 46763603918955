require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en.js?sporks");

export default {
  mode: "sync",
  source: "i18n-data/data/locales",
  context: {
    "bn": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./bn.js?sporks" /* webpackChunkName: "i18n-data-data-locales-bn" */); },
    "cs": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./cs.js?sporks" /* webpackChunkName: "i18n-data-data-locales-cs" */); },
    "da": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./da.js?sporks" /* webpackChunkName: "i18n-data-data-locales-da" */); },
    "de": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./de.js?sporks" /* webpackChunkName: "i18n-data-data-locales-de" */); },
    "el": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./el.js?sporks" /* webpackChunkName: "i18n-data-data-locales-el" */); },
    "en-au": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-au.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-au" */); },
    "en-ca": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-ca.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-ca" */); },
    "en-gb": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-gb.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-gb" */); },
    "en-ie": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-ie.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-ie" */); },
    "en-in": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-in.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-in" */); },
    "en-nz": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-nz.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-nz" */); },
    "en-us": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en-us.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-us" */); },
    "en": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./en.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en" */); },
    "es-ar": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./es-ar.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es-ar" */); },
    "es-mx": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./es-mx.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es-mx" */); },
    "es": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./es.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es" */); },
    "fi": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./fi.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fi" */); },
    "fr-ca": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./fr-ca.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fr-ca" */); },
    "fr": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./fr.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fr" */); },
    "he-il": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./he-il.js?sporks" /* webpackChunkName: "i18n-data-data-locales-he-il" */); },
    "hr": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./hr.js?sporks" /* webpackChunkName: "i18n-data-data-locales-hr" */); },
    "hu": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./hu.js?sporks" /* webpackChunkName: "i18n-data-data-locales-hu" */); },
    "id": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./id.js?sporks" /* webpackChunkName: "i18n-data-data-locales-id" */); },
    "it": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./it.js?sporks" /* webpackChunkName: "i18n-data-data-locales-it" */); },
    "ja": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./ja.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ja" */); },
    "ko": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./ko.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ko" */); },
    "lt": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./lt.js?sporks" /* webpackChunkName: "i18n-data-data-locales-lt" */); },
    "ms": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./ms.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ms" */); },
    "nl": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./nl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-nl" */); },
    "no-no": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./no-no.js?sporks" /* webpackChunkName: "i18n-data-data-locales-no-no" */); },
    "pl": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./pl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-pl" */); },
    "pt-br": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./pt-br.js?sporks" /* webpackChunkName: "i18n-data-data-locales-pt-br" */); },
    "ro": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./ro.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ro" */); },
    "ru": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./ru.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ru" */); },
    "sk": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./sk.js?sporks" /* webpackChunkName: "i18n-data-data-locales-sk" */); },
    "sv": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./sv.js?sporks" /* webpackChunkName: "i18n-data-data-locales-sv" */); },
    "th": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./th.js?sporks" /* webpackChunkName: "i18n-data-data-locales-th" */); },
    "tl": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./tl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-tl" */); },
    "vi": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./vi.js?sporks" /* webpackChunkName: "i18n-data-data-locales-vi" */); },
    "zh-cn": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./zh-cn.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-cn" */); },
    "zh-hk": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./zh-hk.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-hk" */); },
    "zh-tw": function () { return require("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/babel-loader/lib/index.js??ref--10-oneOf-1-0!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.864/node_modules/bender-webpack/lib/use-es6-loader.js!/usr/share/hubspot/build/workspace/cms-free-branding/node_modules/imports-loader/dist/cjs.js??ref--21!./zh-tw.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-tw" */); }  }
};
