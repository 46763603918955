import { getDeviceId } from 'viral-links-lib/utils/tracking';
import { getTrackedIntermediateUrl } from 'viral-links-lib/viral-tracking/viral-urls';
import { getEnv, getPortalId } from './hsVars';

const isQA = () => {
  const hsVars = window.hsVars;

  if (!hsVars) {
    return true;
  }

  if (hsVars.app_hs_base_url && hsVars.app_hs_base_url.indexOf('hubspotqa.com') > -1) {
    return true;
  }

  if (hsVars.cp_hs_base_url && hsVars.cp_hs_base_url.indexOf('hubspotqa.com') > -1) {
    return true;
  }

  return false;
};

export const renderPixel = () => {
  const elem = document.createElement('img');
  elem.setAttribute('style', 'display: none!important;');
  const viralType = 'builder';
  const deviceId = getDeviceId();
  const identifierString = `deviceId=${deviceId}`;
  const src = `https://api.hubspot${isQA() ? 'qa' : ''}.com/viral-links/v1/tracking?${identifierString}&viralLinkType=${viralType}`;
  elem.setAttribute('src', src);
  return elem.outerHTML;
};
export const getLocalTrackedIntermediateUrl = redirectPage => {
  const portalId = getPortalId();
  const environment = getEnv();
  const viralLinkType = 'builder';
  return getTrackedIntermediateUrl({
    redirectPage,
    viralLinkType,
    portalId,
    environment
  });
};