export const TypeformTypeDecorator = {
  url() {
    return `signup-hubspot/integrations`;
  },

  query() {
    return {
      integration: `typeform`
    };
  }

};