import { getHubSpot } from './getGlobal';
import { MetricsFactory } from './MetricsFactory';
let factory;

function setSetupComplete(newValue) {
  getHubSpot().__metricsSetupComplete = newValue;
}

function getSetupComplete() {
  return getHubSpot().__metricsSetupComplete;
} // for randomized Jasmine test runs only


export function __resetMetricsLoaded() {
  if (!factory || !getSetupComplete()) {
    return;
  }

  if (factory.counter('loaded').canFlush()) {
    factory.counter('loaded').flush();
  }

  factory = undefined;
  setSetupComplete(false);
  trackMetricsLoaded();
}
export function trackMetricsLoaded() {
  if (factory || getSetupComplete()) {
    return;
  }

  factory = new MetricsFactory('metrics', {});
  factory.counter('loaded').increment();
  setSetupComplete(true);
}